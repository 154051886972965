<template lang="">
    <div class="field">
        <div class="grid formgrid mt-8">
            <div class="field col-12">
                <div class="card-container">
                    <div class="block font-bold text-center p-2 mb-3"><h1>Processando convocação</h1></div>
                    <div class="block text-center p-4 mb-3">
                        <p>
                            O processo executa em segundo plano, portando é possível acessar outras telas do sistema. <br />Usuário solicitante
                            receberá um notificação informando a conclusão do processo.
                        </p>
                        <p>
                            Estimativa de conclusão: <span class="font-bold">{{ previsaoConclusao }}</span>
                        </p>
                    </div>
                    <div class="block font-bold text-center mb-1">Clientes selecionados</div>
                    <div class="block text-center mb-3">
                        <div v-for="convocacaoCliente in clientes" :key="convocacaoCliente.id">
                            <i
                                v-if="convocacaoCliente.msgErro"
                                v-tooltip.bottom="convocacaoCliente.msgErro"
                                class="pi pi-info-circle"
                                :style="{ color: 'red' }"
                            ></i>
                            <span>{{ convocacaoCliente.cliente.name }}</span>
                        </div>
                    </div>
                    <div class="block font-bold text-center mb-1">Período</div>
                    <div class="block text-center mb-3">
                        {{ $filters.formatDateOnly(form.periodo) }}
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button label="Cancelar" icon="pi pi-times" class="p-button p-button-danger" @click="showCancelarRecord = true" />
                    </div>
                </div>
                <AppDeleteDialog
                    v-model:visible="showCancelarRecord"
                    customTitle="Deseja realmente cancelar a convocação?"
                    labelConfirm="Cancelar"
                    @onConfirm="onClickCancelar"
                    @onClose="onCloseCancelar"
                >
                </AppDeleteDialog>
            </div>
        </div>
    </div>
</template>
<script>
import ConvocacaoService from '../services/ConvocacaoService';
import moment from 'moment-timezone';
import EnumEtapaConvocacao from '@/enums/EnumEtapaConvocacao';
import { showSuccess, showError } from '@/utils/Toast';

export default {
    data() {
        return {
            $service: null,
            form: {},
            clientes: [],
            showCancelarRecord: false
        };
    },
    computed: {
        previsaoConclusao() {
            if (!this.form?.createdAt) {
                return null;
            }
            return moment.tz(this.form.createdAt, 'YYYY-MM-DD', 'America/Sao_Paulo').add(1, 'd').format('DD/MM/YYYY');
        }
    },
    mounted() {
        this.$service = new ConvocacaoService();
        this.load();
    },
    methods: {
        async load() {
            const [responseConvocacao, responseClientes] = await Promise.all([
                this.$service.findById(this.$route.params.id),
                this.$service.clientes(this.$route.params.id)
            ]);

            this.form = responseConvocacao.data;
            this.clientes = responseClientes.data;
            await this.autoLoad();
        },
        async onClickCancelar() {
            try {
                await this.$service.cancelar(this.$route.params.id);
                showSuccess(this.$toast, 'Cancelado com sucesso');
                this.showCancelarRecord = false;
                this.$router.push(`/gestaoMedica/request`);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onCloseCancelar() {
            this.showCancelarRecord = false;
        },
        async autoLoad() {
            const responseConvocacao = await this.$service.findById(this.$route.params.id);
            this.form = responseConvocacao.data;
            if (this.form.etapa == EnumEtapaConvocacao.FUNCIONARIOS) {
                this.$router.push(`/convocacao-exames/${this.$route.params.id}/funcionarios`);
                return;
            }

            setTimeout(async () => {
                await this.autoLoad();
            }, 5000);
        }
    }
};
</script>
